import React, { useState } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";

import "./MyData.scss";
import { Typography } from "../../../components/__common/Typography/Typography";
import { LinkButton, TTSButton } from "../../../components/__common/_controls";
import { Svg, SVG_ICONS } from "../../../components/__common/Svg/Svg";
import { Summary } from "./Sections/Summary/Summary";
import { ManageApps } from "./Sections/ManageApps/ManageApps";
import { UsageGuide } from "./UsageGuide/UsageGuide";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { MyDataState } from "../../../store/reducers/mydata-reducer";
import { useEffect } from "react";
import { useMyDataHooks } from "../../../hooks/mydata-hooks";
import { RequestDetailsModal } from "./RequestDetailsModal/RequestDetailsModal";
import { ROUTES } from "../../../configs/routes";
import { GlobalSiteLinkPopup } from "../../../components/GlobalSiteLinkPopup/GlobalSiteLinkPopup";
import { Trans, useTranslation } from "react-i18next";
import { UserState } from "../../../store/reducers/user-reducer";
import triggerGAEvent from "../../../utils/UtilsTriggerGAEvent";
import {
  EVENT_CATEGORY_MY_DATA,
  EVENT_MY_DATA_ACTION_MORE_INFORMATION,
} from "../../../constants/analytics";
import { HelpTipsPoupup } from "../../../components/HelpTipsPopup/HelpTipsPopup";
import { Container } from "../../../components/Grid/Grid";
import { URLS } from "../../../constants/urls";
import { Cookie } from "../../../services/storage";
import { COOKIE_GST_ATKN } from "../../../constants/storage";
import { UtilsUser } from "../../../utils/UtilsUser";
import { LayoutState } from "../../../store/reducers/layout-reducer";
import LoadingFC from "../../../components/__common/Loading/Loading";
import { useHub } from "../../../hooks/hub-hooks";
import classNames from "classnames";
import { ServiceRequestToastError } from "./ServiceRequestToastError/ServiceRequestToastError";
import { useUserHooks } from "../../../hooks/user-hooks";

export const MyData = () => {
  // third party hooks
  const { t } = useTranslation();
  const history = useHistory();
  const params: any = useParams();

  // custom hooks
  const {
    loadSummary,
    closeGlobalSiteLinkPopup,
    resetServiceRequestErrorToast,
  } = useMyDataHooks();
  const { hasLocalSite } = useHub();
  const { pathname } = useLocation();
  const { isDigitalLegacy } = useUserHooks();
  // local states
  const [isGuideOpen, setGuideOpen] = useState<boolean>(false);
  const [displayToast, setDisplayToast] = useState<boolean>(false);
  // global states
  const {
    isSummaryLoading,
    summary,
    isGlobalSiteLinkOpen,
    serviceRequestToastError,
  } = useSelector<ApplicationState, MyDataState>((state) => state.myData);
  const { userCountryCode, userData, userRegion } = useSelector<
    ApplicationState,
    UserState
  >((state) => state.user);
  const { languageTrans, selectedCountry } = useSelector<
    ApplicationState,
    LayoutState
  >((state) => state.layout);

  // variables
  const isEmailLogin = Cookie.get(COOKIE_GST_ATKN);

  useEffect(
    () => {
      const currentURL = window.location.pathname;
      if (currentURL.includes(URLS.OLD_MYDATA)) {
        history.replace(URLS.MYDATA);
      } else if (userData.countryCode !== "") {
        loadSummary();
      }
    },
    // eslint-disable-next-line
    [languageTrans, userData.countryCode]
  );

  useEffect(() => {
    if (hasLocalSite && !isSummaryLoading && !isDigitalLegacy) {
      history.replace(
        `${summary.count.total_count > 0 ? ROUTES.MYDATA_HISTORY : ROUTES.ROOT}`
      );
    }
  }, [summary, selectedCountry, userCountryCode]);

  useEffect(
    () => {
      const regex = /SP|SPDL-(DEV|STG|[0-9A-Z]*)/g;
      const currentURL = window.location.pathname;
      const reqNum = currentURL.split("/").pop();

      if (reqNum && UtilsUser.isUserLoggedIn() && currentURL.match(regex)) {
        if (hasLocalSite && !isDigitalLegacy) {
          history.replace(`${ROUTES.MYDATA_HISTORY}/${reqNum}`);
        } else {
          history.replace(`${ROUTES.MYDATA}/${reqNum}`);
        }
      }
    },
    // eslint-disable-next-line
    [userCountryCode, selectedCountry, pathname]
  );

  useEffect(() => {
    if (isGuideOpen) {
      document.getElementById("usage-guide-modal-tts")?.focus();
    }
  }, [isGuideOpen]);
  useEffect(() => {
    if (serviceRequestToastError.hasRendered) {
      setDisplayToast(true);
    }

    return () => {
      setDisplayToast(false);
      handleErrorToastClose();
    };
  }, [serviceRequestToastError.hasRendered]);

  const handleErrorToastClose = () => {
    if (
      serviceRequestToastError.isError ||
      serviceRequestToastError.hasRendered
    ) {
      resetServiceRequestErrorToast();
      setDisplayToast(false);
    }
  };

  const getCountrySpecificComponent = (
    country: string,
    isUserRestricted = false
  ) => {
    if (country === "CHN" && !isDigitalLegacy) {
      return (
        <Container className="mydata__china">
          <Svg
            icon={SVG_ICONS.MYDATA_CHINA}
            width={320}
            height={320}
            className="mydata__china__icon"
          />
          <Typography variant="title3" className="mydata__china__title">
            {t("PRIVACY_MYDATA_MSG_CHINA")}
          </Typography>
          <Typography variant="body2" className="mydata__china__text">
            <Trans
              i18nKey="PRIVACY_MYDATA_MSG_CHINA_CNPP_LINK"
              components={{
                a: (
                  // eslint-disable-next-line
                  <a target="_blank" href="https://privacy.samsung.com.cn/" />
                ),
              }}
            />
          </Typography>
        </Container>
      );
    } else if (country === "KOR" && isUserRestricted) {
      return (
        <Container className="mydata__china">
          <Svg
            icon={SVG_ICONS.MYDATA_KOREA_CHILD}
            width={320}
            height={320}
            className="mydata__china__icon"
          />
          <Typography variant="title3" className="mydata__china__title">
            {t("PRIVACY_MYDATA_MSG_CHILDREN_KO")}
          </Typography>
          <Typography variant="body2" className="mydata__china__text">
            {t("PRIVACY_MYDATA_MSG_CHILDREN_KO2")}
          </Typography>
          <Typography variant="body2" className="mydata__china__text__bulleted">
            <Trans
              i18nKey="PRIVACY_MYDATA_MSG_CHILDREN_KO3"
              components={{
                a: (
                  // eslint-disable-next-line
                  <a
                    target="_blank"
                    href="https://www.samsung.com/sec/support"
                  />
                ),
              }}
            />
          </Typography>
        </Container>
      );
    } else if (country === "USA" && isUserRestricted) {
      return (
        <Container className="mydata__china">
          <Svg
            icon={SVG_ICONS.MYDATA_KOREA_CHILD}
            width={320}
            height={320}
            className="mydata__china__icon"
          />
          <Typography variant="body2" className="mydata__china__altText">
            <Trans
              i18nKey="PRIVACY_MYDATA_MSG_CHILDREN_USA"
              components={{
                a: (
                  // eslint-disable-next-line
                  <a
                    target="_blank"
                    href="https://www.samsung.com/us/privacy"
                  />
                ),
              }}
            />
          </Typography>
        </Container>
      );
    } else if (userRegion.isSAFromEurope && isUserRestricted) {
      return (
        <Container className="mydata__china">
          <Svg
            icon={SVG_ICONS.MYDATA_KOREA_CHILD}
            width={320}
            height={320}
            className="mydata__china__icon"
          />
          <Typography variant="body2" className="mydata__china__altText">
            <Trans
              i18nKey="PRIVACY_MYDATA_MSG_CHILDREN_EU"
              components={{
                a: (
                  // eslint-disable-next-line
                  <a
                    target="_blank"
                    href="https://www.samsung.com/request-desk"
                  />
                ),
              }}
            />
          </Typography>
        </Container>
      );
    } else {
      return;
    }
  };

  return isSummaryLoading ? (
    <LoadingFC />
  ) : (
    <>
      {!!params.requestNumber && (
        <RequestDetailsModal
          headerTestid="requestDetail-onhide-button"
          show={!!params.requestNumber}
          requestNumber={params.requestNumber}
          onHide={() => history.replace(ROUTES.MYDATA)}
          onCompleteCancel={loadSummary}
        />
      )}
      {displayToast && (
        <ServiceRequestToastError
          isSAUnverified={serviceRequestToastError.isSAUnverifiedError}
          show={displayToast}
          onClose={handleErrorToastClose}
        />
      )}
      <div
        className={classNames("mydata", {
          "child-account": userRegion.isSAFromEurope && userRegion.isRestricted,
        })}
      >
        <HelpTipsPoupup />
        {isGlobalSiteLinkOpen && !isEmailLogin && (
          <GlobalSiteLinkPopup onHide={closeGlobalSiteLinkPopup} />
        )}

        {isGuideOpen && (
          <UsageGuide
            headertestid="guide-close-button"
            open={isGuideOpen}
            onClickClose={() => setGuideOpen(false)}
          />
        )}
        <div className="mydata__title-section">
          <Typography variant="h2" ss>
            {t("PRIVACY_MYDATA_PAGE_TITLE")}
            <TTSButton
              value={
                t("PRIVACY_MYDATA_PAGE_TITLE") +
                ". " +
                t("PRIVACY_MYDATA_PAGE_DESC")
              }
            />
          </Typography>
          <Typography
            variant="body1"
            weight="w400"
            className="mydata__title-section__sub"
          >
            {t("PRIVACY_MYDATA_PAGE_DESC")}
          </Typography>
          {userData.countryCode !== "CHN" && (
            <LinkButton
              to="#"
              title={t("PRIVACY_MYDATA_PAGE_BTN_GUIDE")}
              iconPosition="left"
              icon={SVG_ICONS.GUIDE}
              underlined={false}
              iconSize={24}
              size="lg"
              textClassName={"mydata__title-section__more"}
              onClick={() => {
                triggerGAEvent(
                  EVENT_CATEGORY_MY_DATA,
                  EVENT_MY_DATA_ACTION_MORE_INFORMATION
                );
                setGuideOpen(true);
              }}
            />
          )}
        </div>
        {!isSummaryLoading &&
          (((userData.countryCode === "KOR" ||
            userData.countryCode === "USA" ||
            userRegion.isSAFromEurope) &&
            userRegion.isRestricted) ||
          (userData.countryCode === "CHN" && !isDigitalLegacy) ? (
            getCountrySpecificComponent(
              userData.countryCode,
              userRegion.isRestricted
            )
          ) : (
            <Summary
              summary={summary}
              isFromChina={userData.countryCode === "CHN" && !isDigitalLegacy}
              isRestricted={userRegion.isRestricted}
              saCountry={userData.countryCode}
            />
          ))}

        {userData.countryCode !== "CHN" && !userRegion.isRestricted && (
          <ManageApps />
        )}
      </div>
    </>
  );
};
