import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import "./Layout.scss";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";
import { useI18Next } from "../../i18next";
import { ScrollToTop } from "../__common/ScrollToTop/ScrollToTop";
import { useEffect } from "react";
import { useUserHooks } from "../../hooks/user-hooks";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { EuCookieAlert, NonEuCookieAlert } from "../CookieModal/CookieModal";
import { UserState } from "../../store/reducers/user-reducer";
import { Cookie, LocalStorage } from "../../services/storage";
import {
  COOKIE_GST_ATKN,
  EMAIL_LOGIN_VERIFICATION_RESULT,
  REDIRECT,
  REDIRECT_URI,
  REQUEST_NUMBER,
  USER_SESSION_ID,
} from "../../constants/storage";
import { useQuery } from "../../hooks/query-hooks";
import { HelpTipsPoupup } from "../HelpTipsPopup/HelpTipsPopup";
import { AUTHENTICATED_ROUTES, ROUTES } from "../../configs/routes";
import { useLayout } from "../../hooks/layout-hooks";
import { UtilsBrowser } from "../../utils/UtilsBrowser";
import { useCheckSiteAccess } from "../../hooks/site-access-hooks";
import classNames from "classnames";
import { URLS } from "../../constants/urls";
import { LoginModal } from "../LoginModal/LoginModal";
import { EmailLoginModal } from "../EmailLoginModal/EmailLoginModal";
import { OTPModal } from "../OTPModal/OTPModal";
import { useLoginOptionHooks } from "../../hooks/login-option-hooks";
import { LoginOptionState } from "../../store/reducers/login-option-reducer";
import { routeShouldRenderComponent } from "../../utils/UtilsLayout";
import {
  EMAIL_LOGIN_SUPPORTED_COUNTRIES,
  ROUTES_NO_COOKIE_NOTICE,
  ROUTES_NO_HEADER_AND_FOOTER,
  ROUTES_NO_HELP_TIPS,
} from "../../configs/layout";
import FocusTrapping from "../__hoc/FocusTrapping";
import { UtilsUseHistory } from "../../utils/UtilsUseHistory";
import { API_BASE_URL } from "../../constants/api";
import { UtilsUser } from "../../utils/UtilsUser";
import { LayoutState } from "../../store/reducers/layout-reducer";
import { SystemState } from "../../store/reducers/site-reducer";
import { useLocale } from "../../hooks/locale-hooks";
import { useAuth } from "../../hooks/auth-hooks";

interface Props {
  children: React.ReactNode;
}

export const Layout: React.FC<Props> = (props) => {
  // third party hooks
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { i18next } = useI18Next();

  // custom hooks
  const query = useQuery();
  const { logout } = useAuth();
  const { getUserData, loadUserCountryCode, isDigitalLegacy } = useUserHooks();
  const { getUserCountry, setSelectedCountry } = useLayout();
  const {
    toggleLoginModal,
    toggleEmailLoginModal,
    toggleEmailOtpModal,
    removeEmailLoginSession,
    removeGstToken,
    removeRedirectSession,
    handleLoginBasedOnGeoIp,
  } = useLoginOptionHooks();

  // states
  const { userCountryCode, isGeoIPLoading } = useSelector<
    ApplicationState,
    UserState
  >((state) => state.user);
  const { isLoginModalOpen, isEmailLoginModalOpen, isEmailOtpModalOpen } =
    useSelector<ApplicationState, LoginOptionState>(
      (state) => state.loginOption
    );
  const { selectedCountry } = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );

  const { isLoading: siteSettingIsLoading } = useSelector<
    ApplicationState,
    SystemState
  >((state) => state.system);

  // variables
  const redirectUri = Cookie.get(REDIRECT_URI);
  const param = query.get("redirect");

  const { go } = UtilsUseHistory();
  const isEmailLoginOngoing = isEmailLoginModalOpen || isEmailOtpModalOpen;
  const isLoginAttemptOngoing =
    isEmailLoginModalOpen || isEmailOtpModalOpen || isLoginModalOpen;
  const redirectToChinaLocalSite =
    userCountryCode === "CN" &&
    !UtilsUser.isUserLoggedIn() &&
    pathname !== ROUTES.DIGITAL_LEGACY_AUTH;

  const handleChinaGeoipRedirect = () => {
    if (UtilsUser.isUserLoggedIn() && isDigitalLegacy) return;

    if (redirectToChinaLocalSite) {
      window.location.replace(URLS.CHINA_LOCALSITE);
    }
  };

  const { loadLocale } = useLocale();
  useEffect(() => {
    if (!userCountryCode) return;
    handleChinaGeoipRedirect();
    loadLocale();

    const country = getUserCountry();
    setSelectedCountry(country);
  }, [userCountryCode]);

  useEffect(() => {
    if (siteSettingIsLoading) return;

    loadUserCountryCode();
    removeEmailLoginSession();
    getUserData();

    const storageDiffChecker1 = (e: any) => {
      if (e.key === USER_SESSION_ID && !e.oldValue && e.newValue) {
        getUserData();
      }
    };

    window.addEventListener("storage", storageDiffChecker1);

    return () => {
      window.removeEventListener("storage", storageDiffChecker1);
    };
  }, [siteSettingIsLoading]);

  useEffect(() => {
    // Redirect to designated page after logging in
    if (redirectUri && query.get("success")) {
      window.location.replace(`${API_BASE_URL}${Cookie.get(REDIRECT_URI)}`);
      Cookie.remove(REDIRECT_URI);
    }

    if (pathname === ROUTES.OLD_ABOUT_GRPS) {
      history.replace(ROUTES.ABOUT_GRPS);
    }
  }, []);

  useEffect(() => {
    window.onpopstate = () =>
      setTimeout(function () {
        if (isEmailLoginOngoing || isLoginModalOpen) {
          toggleEmailOtpModal(false);
          toggleLoginModal({ isLoginModalOpen: false });
          toggleEmailLoginModal(false);
          go(-1);
        }
      }, 0);
  }, [isEmailLoginOngoing, isLoginModalOpen]);

  useEffect(
    () => {
      const emailLoginVerificationResult = LocalStorage.get(
        EMAIL_LOGIN_VERIFICATION_RESULT
      );
      const isVerifying =
        emailLoginVerificationResult &&
        emailLoginVerificationResult === "false";
      if (isVerifying && !isEmailLoginOngoing) {
        removeGstToken();
      }
      if (Cookie.get(COOKIE_GST_ATKN) === undefined) {
        localStorage.removeItem(EMAIL_LOGIN_VERIFICATION_RESULT);
      }
    },
    // eslint-disable-next-line
    [isEmailLoginOngoing]
  );

  useEffect(() => {
    if (!isLoginAttemptOngoing) {
      removeRedirectSession();
    }
  }, [isLoginAttemptOngoing]);

  // Check if user is redirected back to home page after attempting
  // to directly access an authenticated route (deep link)
  useEffect(() => {
    if (!selectedCountry.code || !userCountryCode) return;

    // CHECK IF THE NEW selectedCountry doesn't support email login
    if (
      UtilsUser.isEmailLogin() &&
      !EMAIL_LOGIN_SUPPORTED_COUNTRIES.includes(selectedCountry.code)
    ) {
      // remove email session
      logout();
      removeGstToken();
      sessionStorage.setItem(REDIRECT, ROUTES.MYDATA);
    }

    if (!param) return;

    // to handle deep links with request details, we will check if it inclues "/my-data"
    // this would also affect routes like "/my-data" and "/my-data/history"
    if (AUTHENTICATED_ROUTES.includes(param) || param.includes(ROUTES.MYDATA)) {
      sessionStorage.setItem(REDIRECT, param);

      const reqNum = param.split("/").pop();
      if (reqNum?.match(/(SP)-(DEV|STG|[0-9A-Z]*)/g)) {
        Cookie.set(REQUEST_NUMBER, reqNum);
      }

      handleLoginBasedOnGeoIp();
    }
  }, [search, userCountryCode, selectedCountry]);

  return i18next.isInitialized && !redirectToChinaLocalSite ? (
    <div className="body" data-testid="layout">
      {!UtilsBrowser.isInternetExplorer() &&
        !routeShouldRenderComponent(pathname, ROUTES_NO_HEADER_AND_FOOTER) && (
          <Header />
        )}
      {!UtilsBrowser.isInternetExplorer() &&
        !isGeoIPLoading &&
        !routeShouldRenderComponent(pathname, ROUTES_NO_COOKIE_NOTICE) && (
          <>
            <NonEuCookieAlert />
            <EuCookieAlert />
          </>
        )}

      {!UtilsUser.isUserLoggedIn() && (
        <FocusTrapping
          isActive={isLoginModalOpen}
          elements="button, div.title2, div.modal-body"
        >
          <LoginModal
            headertestid="loginmodal-onclose-button"
            show={isLoginModalOpen}
            onClose={() => {
              toggleLoginModal({ isLoginModalOpen: false });
            }}
          />
        </FocusTrapping>
      )}

      {isEmailLoginModalOpen && (
        <FocusTrapping
          isActive={isEmailLoginModalOpen}
          elements="input, button#cancel-request-button, #confirm-request-button, button.modal-header__close__btn, div.modal-body, div.title2"
        >
          <EmailLoginModal
            isOpen={isEmailLoginModalOpen}
            setIsOpen={toggleEmailLoginModal}
          />
        </FocusTrapping>
      )}
      {isEmailOtpModalOpen && (
        <FocusTrapping
          isActive={isEmailOtpModalOpen}
          elements="input, div.modal-body, div.title2, button"
        >
          <OTPModal
            isOpen={isEmailOtpModalOpen}
            setIsOpen={toggleEmailOtpModal}
          />
        </FocusTrapping>
      )}

      <main
        id="main-body"
        className={classNames({
          "snap-scroll": pathname === ROUTES.ROOT,
        })}
      >
        {!UtilsBrowser.isInternetExplorer() &&
          !routeShouldRenderComponent(pathname, ROUTES_NO_HELP_TIPS) && (
            <HelpTipsPoupup />
          )}

        <div className="layout">{props.children}</div>
        <ScrollToTop />
        {!UtilsBrowser.isInternetExplorer() &&
          !routeShouldRenderComponent(pathname, [ROUTES.ADSSO_LOGOUT]) && (
            <Footer />
          )}
      </main>
    </div>
  ) : (
    <div data-testid="No-access"></div>
  );
};
