// import axios from "axios";

import { ROUTES } from "../configs/routes";
import { API_PUBLIC_AUTH_TOKEN } from "../constants/api";

const Http = {
  getHeaders: async (extraHeaders?: any) => {
    const defaultHeaders = {
      Pragma: "no-cache",
      "Cache-Control": "no-cache",
      "x-sc-useragent": ";;WebPortal=0.1;Windows NT 6.1; WOW64;",
      "Content-type": "application/json;charset=UTF-8",
      "x-sc-client-os-version": 27,
    };

    return extraHeaders
      ? { ...defaultHeaders, ...extraHeaders }
      : defaultHeaders;
  },

  get: async (url: string, extraHeaders?: any, isPublic?: false) => {
    const headers = await Http.getHeaders(extraHeaders);
    const response = await fetch(url, {
      method: "GET",
      headers: isPublic
        ? { Authorization: API_PUBLIC_AUTH_TOKEN }
        : { ...headers },
    });
    const responseData = await response.json();

    if (responseData.rcode > 0 || responseData.message) {
      return Promise.reject(responseData);
    }

    return responseData;
  },

  post: async (
    url: string,
    data?: any,
    extraHeaders?: any,
    controller?: any
  ) => {
    try {
      const headers = await Http.getHeaders(extraHeaders);
      const response = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(data),
        ...controller,
      });

      const responseData = await response.json();

      // handle rcodes error
      if (responseData.rcode > 0) {
        return Promise.reject(responseData);
      }

      return responseData;
    } catch (e: any) {
      console.error(e);
      return;
    }
  },
};

const handleApiError = (e: any) => {
  if (e.rcode === 4010000) {
    window.location.href = ROUTES.LOGIN;
  }
};

export default Http;
