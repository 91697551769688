import React, { useEffect } from "react";
import { Banner } from "./Sections/Banner/Banner";
import ManagePrivacy from "./Sections/ManagePrivacy/ManagePrivacy";
import LearnMore from "./Sections/LearnMore/LearnMore";
import PersonalDataManagement from "./Sections/PersonalDataManagement/PersonalDataManagement";
import { useLoginOptionHooks } from "../../../hooks/login-option-hooks";
import { UtilsUser } from "../../../utils/UtilsUser";
import { ROUTES } from "../../../configs/routes";
import { useUserHooks } from "../../../hooks/user-hooks";

const LandingPage: React.FC = () => {
  const { toggleLoginModal } = useLoginOptionHooks();
  const { isDigitalLegacy } = useUserHooks();
  const hasAccessLoginThruDeepLinked = localStorage.getItem(
    "hasAccessLoginThruDeepLinked"
  );

  const handleScrollOnClick = () => {
    const body = document.getElementById("main-body");
    const section = document.getElementById(
      isDigitalLegacy ? "learn-more" : "manage-privacy"
    );

    body?.scrollTo({ top: section?.offsetTop, behavior: "smooth" });
  };

  useEffect(() => {
    if (
      hasAccessLoginThruDeepLinked === "true" &&
      !UtilsUser.isUserLoggedIn()
    ) {
      toggleLoginModal({
        isLoginModalOpen: true,
        loginModalRedirect: ROUTES.ROOT,
      });
    }
    localStorage.removeItem("hasAccessLoginThruDeepLinked");
  }, [hasAccessLoginThruDeepLinked]);

  return (
    <>
      <Banner onClick={handleScrollOnClick} data-testid="landingPage-banner" />
      {!isDigitalLegacy && <ManagePrivacy />}
      <LearnMore />
      <PersonalDataManagement />
    </>
  );
};

export default LandingPage;
